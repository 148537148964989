<template>
  <nav
    class="hidden px-3 lg:flex"
  >
    <!-- ドロップダウンメニュー -->
    <div class="dropdown dropdown-hover static mr-5 text-co-gray-700 hover:text-co-gray-700">
      <div
        tabindex="0"
        role="button"
        class="bu-nav-link px-1 py-5"
      >
        研修一覧
        <i
          class="fa fa-angle-down co-display-11 ml-1"
          aria-hidden="true"
        />
      </div>
      <div
        tabindex="0"
        class="menu dropdown-content inset-x-0 top-[70px] z-[1030] mx-auto w-full max-w-screen-xl gap-3 overflow-y-scroll rounded-b bg-co-gray-50/[.97] py-3 shadow-[0_6px_12px_rgba(0,0,0,.175)]"
      >
        <div class="flex gap-3">
          <a
            href="https://lp.techacademy.jp/bz-prospective"
            target="_blank"
            class="flex basis-4/12 items-center justify-between border border-co-gray-300 p-3 text-co-gray-700 hover:text-co-gray-400"
          >
            <div>
              <span class="mb-3 block text-[1rem]">内定者研修</span>
              <span class="co-display-14">入社までにITスキルに対する不安を解決</span>
            </div>
            <i
              class="fa fa-chevron-circle-right text-[1.25rem]"
              aria-hidden="true"
            />
          </a>
          <a
            href="https://lp.techacademy.jp/bz-newcomer"
            target="_blank"
            class="flex basis-4/12 items-center justify-between border border-co-gray-300 p-3 text-co-gray-700 hover:text-co-gray-400"
          >
            <div>
              <span class="mb-3 block text-[1rem]">新入社員研修</span>
              <span class="co-display-14">新入社員を「自走できるエンジニア」へ育成</span>
            </div>
            <i
              class="fa fa-chevron-circle-right text-[1.25rem]"
              aria-hidden="true"
            />
          </a>
          <a
            :href="$host() + '/biz/training/course'"
            class="flex basis-4/12 items-center justify-between border border-co-gray-300 p-3 text-co-gray-700 hover:text-co-gray-400"
          >
            <div>
              <span class="mb-3 block text-[1rem]">全研修一覧</span>
              <span class="co-display-14">未経験から学び実務レベルのスキルを習得</span>
            </div>
            <i
              class="fa fa-chevron-circle-right text-[1.25rem]"
              aria-hidden="true"
            />
          </a>
        </div>
        <div class="flex gap-3">
          <a
            href="https://lp.techacademy.jp/bz-selfdevelopment"
            target="_blank"
            class="flex basis-4/12 items-center justify-between border border-co-gray-300 p-3 text-co-gray-700 hover:text-co-gray-400"
          >
            <div>
              <span class="mb-3 block text-[1rem]">自己啓発プラン</span>
              <span class="co-display-14">従業員様への自己啓発・福利厚生として活用</span>
            </div>
            <i
              class="fa fa-chevron-circle-right text-[1.25rem]"
              aria-hidden="true"
            />
          </a>
          <a
            :href="$host() + '/biz/training/dx/'"
            class="flex basis-4/12 items-center justify-between border border-co-gray-300 p-3 text-co-gray-700 hover:text-co-gray-400"
          >
            <div>
              <span class="mb-3 block text-[1rem]">DX研修</span>
              <span class="co-display-14">社内のデジタル知識の向上・業務効率化</span>
            </div>
            <i
              class="fa fa-chevron-circle-right text-[1.25rem]"
              aria-hidden="true"
            />
          </a>
          <span class="flex basis-4/12" />
        </div>
      </div>
    </div>
    <a
      class="bu-nav-link mr-5 px-1 py-5 text-co-gray-700 hover:text-co-gray-700"
      :href="$host() + '/biz/training/case'"
    >
      導入事例
    </a>
    <div
      class="mx-5 flex items-center"
    >
      <a
        :href="$host() + '/biz/training/contact'"
        class="bu-btn-contact"
      >お問い合わせ</a>
    </div>
    <span class="border-l border-co-gray-300" />
    <a
      class="bu-nav-link ml-5 px-1 py-5 text-co-gray-700 hover:text-co-gray-700"
      :href="$host() + '/biz/career'"
    >
      人材採用
    </a>
    <a
      class="bu-nav-link ml-5 px-1 py-5 text-co-gray-700 hover:text-co-gray-700"
      :href="$host() + '/'"
    >
      個人向け
    </a>
  </nav>
</template>

<style scoped>
/* リンク下の線 */
.bu-nav-link {
  position: relative;
  @media (max-width: 1199.98px) {
    font-size: 0.875rem;
  }
  &::after {
    position: absolute;
    bottom: 0.875rem;
    left: 0;
    width: 100%;
    height: 3px;
    content: '';
    @apply bg-co-primary-500;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(6px);
  }
  &:hover,
  &:focus {
    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
