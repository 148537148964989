<template>
  <a
    :href="$host() + '/biz/training'"
    class="bu-navbar-brand h-full"
  >
    <p class="bu-copy-word mb-0 block text-left text-black">
      自走できるエンジニアを輩出する
    </p>
    <img
      class="h-auto max-w-full"
      src="~/assets/images/Bu/BuCommon/Logo/logo-training.svg"
      alt="テックアカデミービジネス"
    >
  </a>
</template>

<style scoped>
/* ロゴ */
.bu-navbar-brand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 10.5rem;
  margin-right: 1rem;
  @media (min-width: 1200px) {
    width: 15rem;
  }
}

/* キャッチコピーテキスト */
.bu-copy-word {
  padding-bottom: 0.125rem;
  margin-left: 1rem;
  font-size: 0.625rem;
  line-height: 1;
  transform: scale(0.8);
  @media (min-width: 1200px) {
    margin-left: 2.8125rem;
    transform: scale(1);
  }
}
</style>
